import axios from "axios";
import { USERS_URL } from "../api/userApi";
import {
  getUsersFailed,
  getUsersRequest,
  getUsersSuccess,
} from "../reducers/userReducer";

export const getAllUsers = (token, dispatch) => {
  dispatch(getUsersRequest());
  axios
    .get(USERS_URL, { headers: { "auth-token": token } })
    .then((res) => {
      dispatch(getUsersSuccess(res.data));
    })
    .catch(() => {
      dispatch(getUsersFailed());
    });
};
