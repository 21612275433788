import { Button } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AccountModal } from "../../../components/mollecules";

const Profile = () => {
  const { admin, account } = useSelector((state) => state.adminReducer);
  const [toggleModal, setToggleModal] = useState(false);
  return (
    <div>
      {toggleModal && <AccountModal closeModal={() => setToggleModal(false)} />}
      <div>{admin.email}</div>
      {account && (
        <div>
          <h2>L'entreprise</h2>
          <div>Nom : {account.name}</div>
          <div>Adresse : {account.location.address}</div>
          <div>Email : {account.email}</div>
          <div>Numéro de téléphone : {account.phone}</div>
          <div>Horaire d'ouverture : {account.hourly}</div>
        </div>
      )}
      <Button onClick={() => setToggleModal(true)} type="primary">
        {account
          ? "Modifier les informations de l'entreprise"
          : "Ajouter les informations de l'entreprise"}
      </Button>
    </div>
  );
};

export default Profile;
