import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Header } from "../components/templates";
import { Articles, Home, Orders, Profile, Users } from "../pages/app";
import { Login } from "../pages/auth";
import { getAccount, getUser } from "../redux/actions/adminActions";
import { getArticles, getOrders } from "../redux/actions/articleActions";
import { getAllUsers } from "../redux/actions/userActions";

const Navigation = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.adminReducer);
  useEffect(() => {
    const tokenFromStorage = localStorage.getItem("token");
    if (tokenFromStorage) {
      const body = {
        token: tokenFromStorage,
      };
      getUser(body, dispatch);
    }
  }, [dispatch]);
  useEffect(() => {
    if (token) {
      getArticles(token, dispatch);
      getAccount(token, dispatch);
      getOrders(token, dispatch);
      getAllUsers(token, dispatch);
    }
  }, [dispatch, token]);
  const authRoutes = () => (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<Navigate replace to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
  const adminRoutes = () => (
    <BrowserRouter>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/utilisateurs" element={<Users />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/*" element={<Navigate replace to="/" />} />
        </Routes>
      </main>
    </BrowserRouter>
  );
  return token ? adminRoutes() : authRoutes();
};

export default Navigation;
