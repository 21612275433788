import { Button, Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { filteredPrice } from "../../../utils/filteredPrice";
import "./styles.css";

function ArticlesList(props) {
  const { editModal } = props;
  const { articles } = useSelector((state) => state.articleReducer);

  return (
    <div>
      <Row className="home_head_table">
        <Col xl={1} className="h-auto">
          <div className="home_head_table_cell">Image</div>
        </Col>
        <Col xl={3} className="h-auto">
          <div className="home_head_table_cell">Nom</div>
        </Col>
        <Col xl={2} className="h-auto">
          <div className="home_head_table_cell">Prix jour (HT)</div>
        </Col>
        <Col xl={3} className="h-auto">
          <div className="home_head_table_cell">Prix jour WE (HT)</div>
        </Col>
        <Col xl={3} className="h-auto">
          <div className="home_head_table_cell">Prix livraison/km (HT)</div>
        </Col>
        <Col xl={3} className="h-auto">
          <div className="home_head_table_cell">Prix de la caution</div>
        </Col>
        <Col xl={3} className="h-auto">
          <div className="home_head_table_cell">Poids max (PTAC)</div>
        </Col>
        <Col xl={2} className="h-auto">
          <div className="home_head_table_cell">Hauteur max</div>
        </Col>
        <Col xl={2} className="h-auto">
          <div className="home_head_table_cell">Active</div>
        </Col>
        <Col xl={2} className="h-auto">
          <div className="home_head_table_cell">Action</div>
        </Col>
      </Row>
      {articles.map((article) => (
        <Row key={article._id} className="home_body_table">
          <Col xs={1} xl={1} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_left text-center">
              <img
                src={article.image}
                style={{
                  height: 50,
                  width: 50,
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                alt={article.name}
              />
            </div>
          </Col>
          <Col xs={22} xl={3} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_right home_body_table_title">
              {article.name}
            </div>
          </Col>
          <Col xs={12} xl={2} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_right">
              {filteredPrice(article.price.daily)}
            </div>
          </Col>
          <Col xs={12} xl={3} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_right">
              {filteredPrice(article.price.weekly)}
            </div>
          </Col>
          <Col xs={12} xl={3} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_right">
              {filteredPrice(article.price.delivery)}
            </div>
          </Col>
          <Col xs={12} xl={3} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_right">
              {filteredPrice(article.price.deposit)}
            </div>
          </Col>
          <Col xl={3} className="h-auto">
            <div className="home_head_table_cell">{article.maxWeight}</div>
          </Col>
          <Col xl={2} className="h-auto">
            <div className="home_head_table_cell">{article.maxHeight}</div>
          </Col>
          <Col xs={8} xl={2} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_left">
              {article.active ? (
                <div className="active-item">Active</div>
              ) : (
                <div className="inactive-item">Inactive</div>
              )}
            </div>
          </Col>
          <Col xs={24} xl={2} className="h-auto">
            <div className="p5 h-full home_head_table_cell">
              <Button
                className="w-full"
                type="primary"
                onClick={() => editModal(article._id)}
              >
                Modifier
              </Button>
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
}

export default ArticlesList;
