import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./reducers/adminReducer";
import articleReducer from "./reducers/articleReducer";
import userReducer from "./reducers/userReducer";

export default configureStore({
  reducer: {
    adminReducer: adminReducer,
    articleReducer: articleReducer,
    userReducer: userReducer,
  },
});
