import { createSlice } from "@reduxjs/toolkit";

export const userReducer = createSlice({
  name: "user",
  initialState: {
    users: [],
    usersLoading: false,
    userLoading: false,
  },
  reducers: {
    getUsersRequest: (state) => {
      state.usersLoading = true;
    },
    getUsersSuccess: (state, action) => {
      state.users = action.payload;
      state.usersLoading = false;
    },
    getUsersFailed: (state) => {
      state.usersLoading = false;
    },
  },
});

export const { getUsersRequest, getUsersSuccess, getUsersFailed } =
  userReducer.actions;

export default userReducer.reducer;
