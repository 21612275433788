import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import { Button, Form, Input, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAccount, updateAccount } from "../../../redux/actions/adminActions";
import "./styles.css";

const libs = ["places"];

function AccountModal(props) {
  const { token, account } = useSelector((state) => state.adminReducer);
  const inputRef = useRef();
  const { closeModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [address, setAddress] = useState("");
  const [geometry, setGeometry] = useState("");

  useEffect(() => {
    if (account) {
      let fields = form.getFieldsValue();
      setAddress(account.location.address);
      fields = {
        ...fields,
        name: account.name,
        email: account.email,
        phone: account.phone,
        hourly: account.hourly,
        address: account.location.address,
        geometry: account.location.geometry,
      };
      form.setFieldsValue(fields);
    }
  }, [account, form]);

  const onAddInformations = (values) => {
    const body = {
      ...values,
      location: {
        address,
        geometry,
      },
    };
    addAccount(token, body, closeModal, dispatch);
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: libs,
  });
  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setAddress(place.formatted_address);
      setGeometry(place.geometry.location);
      let fields = form.getFieldsValue();
      fields = {
        ...fields,
        address: place.formatted_address,
      };
      form.setFieldsValue(fields);
    }
  };

  const onUpdateInformations = (values) => {
    const body = {
      ...values,
      location: {
        address,
        geometry,
      },
    };
    updateAccount(token, account._id, body, closeModal, dispatch);
  };

  return (
    <Modal
      title={
        account
          ? "Modifier les informations de l'entreprise"
          : "Ajouter les informations de l'entreprise"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
    >
      <Form
        layout="vertical"
        onFinish={account ? onUpdateInformations : onAddInformations}
        form={form}
        initialValues={{
          name: "",
          order: "",
          active: true,
          isReservable: true,
        }}
      >
        <Form.Item
          label="Nom du l'entreprise"
          name="name"
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de l'entreprise'!",
            },
          ]}
        >
          <Input placeholder="Casanova" />
        </Form.Item>
        <Form.Item
          label="Adresse de l'entreprise"
          name="address"
          rules={[
            {
              required: true,
              message: "Veuillez ajouter l'adresse de l'entreprise'!",
            },
          ]}
        >
          {!isLoaded ? (
            <div>Loading...</div>
          ) : (
            <StandaloneSearchBox
              onLoad={(ref) => (inputRef.current = ref)}
              onPlacesChanged={handlePlaceChanged}
            >
              <div className="relative">
                <Input
                  placeholder="917 rue de Sacy 60680 Grandfresnoy"
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </StandaloneSearchBox>
          )}
        </Form.Item>
        <Form.Item
          label="Email de contact"
          name="email"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner une adresse email de contact!",
            },
          ]}
        >
          <Input placeholder="casanova@gmail.com" />
        </Form.Item>
        <Form.Item
          label="Numéro de téléphone"
          name="phone"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner un numéro de téléphone!",
            },
          ]}
        >
          <Input placeholder="0123456789" />
        </Form.Item>
        <Form.Item
          label="Horaire d'ouverture"
          name="hourly"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner les horaires d'ouverture!",
            },
          ]}
        >
          <Input placeholder="Lun au Ven de 10h-19h, Samedi 10h-17h" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AccountModal;
