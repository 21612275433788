import React from "react";
import { UsersList } from "../../organisms";
import "./styles.css";

const UsersTemplate = () => {
  return (
    <div>
      <div>
        <UsersList />
      </div>
    </div>
  );
};

export default UsersTemplate;
