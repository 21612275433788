import { createSlice } from "@reduxjs/toolkit";

export const articleReducer = createSlice({
  name: "article",
  initialState: {
    articles: [],
    articlesLoading: false,
    articleLoading: false,
  },
  reducers: {
    getArticlesRequest: (state) => {
      state.articlesLoading = true;
    },
    getArticlesSuccess: (state, action) => {
      state.articles = action.payload;
      state.articlesLoading = false;
    },
    getArticlesFailed: (state) => {
      state.articlesLoading = false;
    },
    addArticleRequest: (state) => {
      state.articleLoading = true;
    },
    addArticleSuccess: (state) => {
      state.articleLoading = false;
    },
    addArticleFailed: (state) => {
      state.articleLoading = false;
    },
  },
});

export const {
  getArticlesRequest,
  getArticlesSuccess,
  getArticlesFailed,
  addArticleRequest,
  addArticleSuccess,
  addArticleFailed,
} = articleReducer.actions;

export default articleReducer.reducer;
