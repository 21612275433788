import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";

function UsersList(props) {
  const { users } = useSelector((state) => state.userReducer);

  return (
    <div>
      <Row className="home_head_table">
        <Col xl={2} className="h-auto">
          <div className="home_head_table_cell">Civilité</div>
        </Col>
        <Col xl={4} className="h-auto">
          <div className="home_head_table_cell">Nom</div>
        </Col>
        <Col xl={4} className="h-auto">
          <div className="home_head_table_cell">Prénom</div>
        </Col>
        <Col xl={3} className="h-auto">
          <div className="home_head_table_cell">Type</div>
        </Col>
        <Col xl={3} className="h-auto">
          <div className="home_head_table_cell">Email</div>
        </Col>
        <Col xl={3} className="h-auto">
          <div className="home_head_table_cell">Numéro de téléphone</div>
        </Col>
        <Col xl={5} className="h-auto">
          <div className="home_head_table_cell">Informations</div>
        </Col>
      </Row>
      {users.map((user) => (
        <Row key={user._id} className="home_body_table">
          <Col xs={1} xl={2} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_left toCapitalize">
              {user?.civility}
            </div>
          </Col>
          <Col xs={22} xl={4} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_right home_body_table_title toUppercase">
              {user?.lastname}
            </div>
          </Col>
          <Col xs={12} xl={4} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_right">
              {user?.firstname}
            </div>
          </Col>
          <Col xs={12} xl={3} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_right">
              {user?.type === "private" ? "Particulier" : "Professionnel"}
            </div>
          </Col>
          <Col xs={12} xl={3} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_right">
              {user?.email}
            </div>
          </Col>
          <Col xs={12} xl={3} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_right">
              {user?.phone}
            </div>
          </Col>
          <Col xs={8} xl={5} className="h-auto">
            {user?.type === "public" ? (
              <div className="home_head_table_cell">
                <div>Nom: {user.company.name}</div>
                <div>Adresse: {user.location.address}</div>
                <div>Siret: {user.company.siret}</div>
                <div>APE: {user.company.ape}</div>
                <div>
                  Création:{" "}
                  {new Date(user.company.creation).toLocaleDateString("fr")}
                </div>
              </div>
            ) : (
              <div className="home_head_table_cell">
                {user?.location?.address}
              </div>
            )}
          </Col>
        </Row>
      ))}
    </div>
  );
}

export default UsersList;
