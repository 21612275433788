import React, { useState } from "react";
import { ArticleModal } from "../../mollecules";
import { ArticlesList, OpelModal } from "../../organisms";

function ArticlesTemplate() {
  const [toggleArticleModal, setToggleArticleModal] = useState({
    active: false,
    category: null,
  });

  const openArticleModal = () => {
    setToggleArticleModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closeArticleModal = () => {
    setToggleArticleModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const editArticleModal = (id) => {
    setToggleArticleModal((prevState) => ({
      ...prevState,
      active: true,
      category: id,
    }));
  };
  return (
    <div>
      {toggleArticleModal.active && (
        <ArticleModal
          closeModal={closeArticleModal}
          toggleModal={toggleArticleModal}
        />
      )}
      <OpelModal title="Ajouter un article" openModal={openArticleModal} />
      <ArticlesList editModal={editArticleModal} />
    </div>
  );
}

export default ArticlesTemplate;
