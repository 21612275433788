import axios from "axios";
import { toast } from "react-toastify";
import { ORDERS_URL } from "../api/adminApi";
import { ARTICLE_IMAGE_URL, ARTICLES_URL } from "../api/articleApi";
import {
  getOrdersFailed,
  getOrdersRequest,
  getOrdersSuccess,
} from "../reducers/adminReducer";
import {
  addArticleFailed,
  addArticleRequest,
  addArticleSuccess,
  getArticlesFailed,
  getArticlesRequest,
  getArticlesSuccess,
} from "../reducers/articleReducer";

export const getArticles = (token, dispatch) => {
  dispatch(getArticlesRequest());
  axios
    .get(ARTICLES_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch(getArticlesSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(getArticlesFailed());
    });
};

export const getArticle = (token, id) => {
  return axios.get(`${ARTICLES_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addArticleImage = (token, body) => {
  return axios.post(ARTICLE_IMAGE_URL, body, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addArticle = (token, body, closeModal, dispatch) => {
  console.log(body);
  dispatch(addArticleRequest());
  axios
    .post(ARTICLES_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addArticleSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getArticles(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addArticleFailed());
    });
};

export const updateArticle = (token, id, body, closeModal, dispatch) => {
  dispatch(addArticleRequest());
  axios
    .put(`${ARTICLES_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addArticleSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getArticles(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addArticleFailed());
    });
};

export const getOrders = (token, dispatch) => {
  dispatch(getOrdersRequest());
  axios
    .get(ORDERS_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(getOrdersSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getOrdersFailed());
    });
};