import { createSlice } from "@reduxjs/toolkit";

export const adminReducer = createSlice({
  name: "admin",
  initialState: {
    token: null,
    admin: null,
    account: null,
    adminLoading: false,
    orders: [],
    ordersLoading: false,
  },
  reducers: {
    loginAdminRequest: (state) => {
      state.adminLoading = true;
    },
    loginAdminSuccess: (state, action) => {
      state.token = action.payload.token;
      state.admin = action.payload.admin;
      state.adminLoading = false;
      localStorage.setItem("token", action.payload.token);
    },
    loginAdminFailed: (state) => {
      state.adminLoading = false;
    },
    getAccountRequest: (state) => {
      state.accountLoading = true;
    },
    getAccountSuccess: (state, action) => {
      state.accountLoading = false;
      state.account = action.payload;
    },
    getAccountFailed: (state) => {
      state.accountLoading = false;
    },
    addAccountRequest: (state) => {
      state.accountLoading = true;
    },
    addAccountSuccess: (state) => {
      state.accountLoading = false;
    },
    addAccountFailed: (state) => {
      state.accountLoading = false;
    },
    updateAccountRequest: (state) => {
      state.accountLoading = true;
    },
    updateAccountSuccess: (state) => {
      state.accountLoading = false;
    },
    updateAccountFailed: (state) => {
      state.accountLoading = false;
    },
    getOrdersRequest: (state) => {
      state.ordersLoading = true;
    },
    getOrdersSuccess: (state, action) => {
      state.ordersLoading = false;
      state.orders = action.payload;
    },
    getOrdersFailed: (state) => {
      state.ordersLoading = false;
    },
  },
});

export const {
  loginAdminRequest,
  loginAdminSuccess,
  loginAdminFailed,
  getAccountRequest,
  getAccountSuccess,
  getAccountFailed,
  addAccountRequest,
  addAccountSuccess,
  addAccountFailed,
  updateAccountRequest,
  updateAccountSuccess,
  updateAccountFailed,
  getOrdersRequest,
  getOrdersSuccess,
  getOrdersFailed,
} = adminReducer.actions;

export default adminReducer.reducer;
